<template>
	<div>
		<div class="topBar">
			<CloHeading level="1" size="2" thin reverse class="u-margin-none">Discover Birds of the World</CloHeading>
			<div class="topBar-button">
				<a href="#subscribe" class="Button Button--subscribe u-margin-none">Subscribe now</a>
			</div>
		</div>
		<div class="midBar">
			<a href="#how-it-works">How it works</a>
			<a href="#previews">Free previews</a>
		</div>
		<div class="funnel yellowlegs" v-funnel-trigger:[0]="animate">
			<img
				class="u-hideForLarge"
				src="https://is-headless-wordpress-prod-s3.s3.amazonaws.com/wp-content/uploads/sites/4/2022/01/yellowlegs_small.jpg"
				alt=""
			/>
			<div class="text" :class="{ animate: animateSection[0] }">
				<CloHeading level="2" size="2" thin class="u-stack-responsive">Powerful, innovative, easy to use!</CloHeading>
				<p class="largeText u-stack-responsive">
					Explore authoritative life histories of the world's birds with the most in-depth ornithology resource ever created.
				</p>

				<ul class="styled u-stack-responsive">
					<li>Expand your knowledge of birds</li>
					<li>Improve your birding skills</li>
					<li>Explore the birds of your region</li>
				</ul>
				<p class="mediumText u-stack-md">No other resource compares. Details below.</p>
				<p class="u-text-2 u-margin-none">*Birds of the World is offered as a premium online subscription service. <a href="/bow/support/scholarships">Scholarships available</a></p>
			</div>
			<div class="credit">Lesser Yellowlegs by Ryan Sanderson/Macaulay Library</div>
		</div>
		<div class="funnel articles" v-funnel-trigger:[1]="animate">
			<img
				class="u-hideForLarge"
				src="https://is-headless-wordpress-prod-s3.s3.amazonaws.com/wp-content/uploads/sites/4/2022/02/southernfulmar_small.jpg"
				alt=""
				loading="lazy"
			/>
			<div class="text right u-colorReverse" :class="{ animate: animateSection[1] }">
				<CloHeading level="2" size="2" thin reverse class="u-stack-responsive"
					>Deepen your understanding of birds and bird behavior</CloHeading
				>
				<ul class="styled">
					<li>11,017 species accounts</li>
					<li>251 family accounts</li>
					<li>Continually updated by leading ornithologists</li>
				</ul>
				<div class="hex u-showForLarge u-mtl">
					<ul class="hex-grid__list">
						<li v-for="(article, index) in articles" :key="index" class="hex-grid__item">
							<span class="hex-grid__content">
								{{ article.name }}
							</span>
						</li>
					</ul>
				</div>
			</div>
			<div class="credit reverse">Southern Fulmar by Peter Vaughan/Macaulay Library</div>
		</div>
		<div class="funnel maps" v-funnel-trigger:[2]="animate">
			<div class="text overlay white" :class="{ animate: animateSection[2] }">
				<CloHeading level="2" size="2" thin class="u-stack-responsive">Discover where birds are and where they go</CloHeading>
				<ul class="styled u-stack-md">
					<li>Seasonal abundance and migration maps</li>
					<li>Range maps of real-time eBird observations</li>
					<li>Expertly curated range maps</li>
				</ul>
				<p class="u-text-2 u-margin-none">Powerfully integrated with eBird</p>
			</div>
			<div v-if="false" class="ebirdCredit">
				<span>Powerfully integrated with</span>
				<img src="https://clo-brand-static-prod.s3.amazonaws.com/logos/ebird/clo_ebird_short_web.svg" alt="eBird" />
			</div>
		</div>
		<div class="funnel multimedia" v-funnel-trigger:[3]="animate">
			<img
				class="u-hideForLarge"
				src="https://is-headless-wordpress-prod-s3.s3.amazonaws.com/wp-content/uploads/sites/4/2022/01/sunbird.jpg"
				alt=""
				loading="lazy"
			/>
			<div class="text right" :class="{ animate: animateSection[3] }">
				<CloHeading level="2" size="2" thin reverse class="u-stack-responsive">Learn songs, calls, and identification</CloHeading>
				<ul class="styled u-stack-responsive u-colorReverse">
					<li>Detailed appearance information (plumages, molts, measurements)</li>
					<li>Photos, videos, and sound recordings &mdash; a view from every angle</li>
					<li>Detailed captions</li>
				</ul>
				<img
					class="spect u-stack-sm"
					src="https://is-headless-wordpress-prod-s3.s3.amazonaws.com/wp-content/uploads/sites/4/2022/01/spect.png"
					alt="Example of audio visualization"
				/>
				<p class="u-text-2 u-colorReverse u-margin-none">Powerfully integrated with the Cornell Lab | Macaulay Library</p>
			</div>

			<div v-if="false" class="mlCredit">
				<div>Powerfully integrated with</div>
				<img src="https://clo-brand-static-prod.s3.amazonaws.com/logos/ml/clo_macaulaylibrary_horizontal_web.svg" alt="Macaulay Library" />
			</div>
			<div class="credit reverse">Mrs. Gould's Sunbird by Ian Davies/Macaulay Library</div>
		</div>
		<div class="funnel taxonomy" v-funnel-trigger:[4]="animate">
			<div class="text" :class="{ animate: animateSection[4] }">
				<CloHeading level="2" size="2" thin reverse class="u-stack-responsive">Master bird families and subspecies</CloHeading>
				<ul class="styled u-colorReverse">
					<li>Taxonomic histories</li>
					<li>Annual Clements/eBird taxonomy updates</li>
					<li>Jobling's Key to Scientific Names</li>
					<li>Original description, protonym, and type locality</li>
					<li>Color plates for every species</li>
				</ul>
			</div>
			<div class="images" :class="{ animate: animateSection[4] }">
				<img
					src="https://is-headless-wordpress-prod-s3.s3.amazonaws.com/wp-content/uploads/sites/4/2022/02/wagtails_2.png"
					alt="Example of species illustrations"
				/>
				<img
					src="https://is-headless-wordpress-prod-s3.s3.amazonaws.com/wp-content/uploads/sites/4/2022/01/subspecies.png"
					alt="Example of subspecies articles"
					class="wagtailSubspecies"
				/>
			</div>
		</div>
		<div class="funnel global" v-funnel-trigger:[5]="animate">
			<div class="images left" :class="{ animate: animateSection[5] }">
				<div class="illustrationCards">
					<div class="illustrationCard" v-for="(item, index) in illustrations" :key="index">
						<IllustrationCard :item="item" :mlDownloadBase="mlDownloadBase" />
					</div>
				</div>
			</div>
			<div class="text right overlay" :class="{ animate: animateSection[5] }">
				<CloHeading level="2" size="2" thin reverse class="u-stack-responsive">Personalize the platform</CloHeading>
				<ul class="styled u-colorReverse u-stack-md">
					<li>Common names available in 90+ languages and language variations (article text in English)</li>
					<li>Regional filter</li>
					<li>Integrates with your eBird life list</li>
				</ul>
				<p class="u-colorReverse u-margin-none">
					<a href="/bow/content/about-birds-of-the-world">Explore other features and content</a>
				</p>
			</div>
		</div>
		<div class="subscribe">
			<div class="anchor">
				<a id="subscribe"></a>
			</div>
			<CloHeading level="2" size="2" thin class="u-stack-md">Ready to subscribe?</CloHeading>
			<CloHeading level="3" size="2" class="u-stack-md">Choose a plan</CloHeading>
			<p>(Cancel anytime!)</p>
			<div class="cards">
				<div class="card">
					<div class="card-header">Monthly plan</div>
					<div class="card-price">${{ formatPrice(nongiftPlans[0].price, 2) }} / month</div>
					<div class="card-button">
						<a
							:href="subscriptionUrl + '?checkoutButtonId=cb-checkout-' + nongiftPlans[0].id"
							class="Button Button--large Button--error Button--subscribe u-margin-none"
							>Subscribe</a
						>
					</div>
				</div>
				<div class="card">
					<div class="card-header">Annual plan</div>
					<div class="card-price">${{ formatPrice(nongiftPlans[1].price, 0) }} / year</div>
					<div class="card-button">
						<a
							:href="subscriptionUrl + '?checkoutButtonId=cb-checkout-' + nongiftPlans[1].id"
							class="Button Button--large Button--error Button--subscribe u-margin-none"
							>Subscribe</a
						>
					</div>
				</div>
			</div>

			<div v-if="giftPlans.length" class="cards">
				<div class="card">
					<div class="card-header">1-yr Gift Subscription</div>
					<div class="card-price">${{ formatPrice(giftPlans[0].price, 0) }}</div>
					<p class="u-margin-none u-text-3">(one-time charge)</p>
					<div class="card-button">
						<template v-if="!user">
							<button
								class="Button Button--large Button--error Button--subscribe" 
								type="button"
								:id="'cb-checkout-' + giftPlans[0].id"
								data-cb-type="checkout"
								:data-cb-plan-id="giftPlans[0].id"
								:data-cbclick="'subscribeLoggedOut-' + giftPlans[0].id"
								@click="openChargeBeePortal"
							>
								Purchase now
							</button>
							<p class="u-margin-none u-text-3">
								<span class="u-inline-sm">or</span>
								<a :href="subscriptionUrl + '?checkoutButtonId=cb-checkout-' + giftPlans[0].id">Sign in and purchase now</a>
							</p>
						</template>
						<a v-else class="Button Button--large Button--highlight u-margin-none" :href="subscriptionUrl + '?checkoutButtonId=cb-checkout-' + giftPlans[0].id">Purchase now</a>
					</div>
				</div>
			</div>
			<p>
				<strong>Institutional Subscription</strong> (library, NGO, agency, etc)
				<a :href="subscribeInstitutionalUrl"
					>Learn more <span class="is-visuallyHidden"> about institutional subscriptions</span></a
				>
			</p>
		</div>
		<div class="howitworks">
			<div class="anchor">
				<a id="how-it-works"></a>
			</div>
			<CloHeading level="2" size="2" thin reverse class="u-stack-responsive">How it works</CloHeading>
			<p>
				A Birds of the World subscription gives you 24/7 digital access to this entire collection of species and family accounts,
				including all maps, data, and multimedia. Our worldwide editorial network is constantly updating species accounts - you'll
				have access to those updates in real time.
			</p>

			<p>
				By subscribing, you are accepting the <a :href="termsOfUseUrl">Cornell Lab of Ornithology Terms of Use</a>, the
				<a :href="bowTermsOfUseUrl">Birds of the World Terms of Use</a>, <a :href="privacyPolicyUrl">Privacy Policy</a>, and
				<a :href="termsOfSaleUrl">Terms of Sale</a>. All plans are subject to applicable taxes. Introductory prices are valid for
				the first term and only for new subscribers.
			</p>

			<p>
				A subscription is automatically renewed at the end of its term. Depending on the plan you choose, you will be charged
				monthly or yearly, adjusted for applicable taxes and/or discounts. We think you'll love having access to this deep,
				authoritative species guide, but you can cancel anytime. That means you can take time to explore the species accounts, but
				not commit to another term - just cancel before your term ends.
				<a :href="subscriptionUrl">Manage your subscription here</a> and if you have any questions,
				<a href="/bow/support/contact">contact us</a>.
			</p>
		</div>
		<div class="previews">
			<div class="anchor">
				<a id="previews"></a>
			</div>
			<CloHeading level="2" size="2" thin class="u-stack-responsive u-textCenter">Examine free preview accounts</CloHeading>
			<div><FreePreviews :mlDownloadBase="mlDownloadBase" /></div>
		</div>
	</div>
</template>

<script>
import Vue from 'vue';

Vue.directive('funnel-trigger', {
	bind: (el, binding) => {
		const io = new IntersectionObserver(
			(entries) => {
				entries.forEach((entry) => {
					if (entry.intersectionRatio > 0.3) {
						binding.value(binding.arg);
					}
				});
			},
			{ threshold: 0.5 }
		);
		io.observe(el);
	},
});

import CloHeading from '@/base/CloHeading/CloHeading.vue';
import CloIcon from '@/icons/CloIcon/CloIcon.vue';
import CloLogoEbird from '@/logos/CloLogoEbird/CloLogoEbird.vue';
import CloLogoMacaulayLockup from '@/logos/CloLogoMacaulayLockup/CloLogoMacaulayLockup.vue';
import IllustrationCard from './IllustrationCard.vue';
import FreePreviews from './FreePreviews.vue';

export default {
	components: {
		CloHeading,
		CloIcon,
		CloLogoEbird,
		CloLogoMacaulayLockup,
		IllustrationCard,
		FreePreviews,
	},
	props: {
		giftPlans: {
			type: Array,
			default() {
				return [];
			},
		},
		nongiftPlans: {
			type: Array,
			default() {
				return [];
			},
		},
		specialOfferPlans: {
			type: Array,
			default() {
				return [];
			},
		},
		user: {
			type: Boolean,
			default: false,
		},
		termsOfSaleUrl: {
			type: String,
			required: true,
		},
		termsOfUseUrl: {
			type: String,
			required: true,
		},
		bowTermsOfUseUrl: {
			type: String,
			required: true,
		},
		privacyPolicyUrl: {
			type: String,
			required: true,
		},
		subscriptionUrl: {
			type: String,
			required: true,
		},
		subscribeInstitutionalUrl: {
			type: String,
			required: true,
		},
		mlDownloadBase: {
			type: String,
			required: true,
		},
	},
	data() {
		return {
			animateSection: [true, false, false, false, false],
			articles: [
				{ name: 'Appearance' },
				{ name: 'Systematics' },
				{ name: 'Distribution' },
				{ name: 'Habitat' },
				{ name: 'Migration' },
				{ name: 'Diet and Foraging' },
				{ name: 'Sounds and Vocal Behavior' },
				{ name: 'Behavior' },
				{ name: 'Demography and Populations' },
				{ name: 'Breeding' },
				{ name: 'Conservation' },
				{ name: 'Priorities for Future Research' },
				{ name: 'Multimedia' },
				{ name: 'Tables and Appendices' },
				//{ name: 'Revision History' },
			],
			illustrations: [
				{
					commonName: '(Ευρωπαϊκή) Αλκυόνη',
					scientificName: 'Alcedo atthis',
					mainIllustrationAssetId: '151464121',
					mainIllustrationCredit: 'Illustration by Norman Arlott.',
				},
				{
					commonName: 'Alción Rojizo',
					scientificName: 'Halcyon coromanda',
					mainIllustrationAssetId: '151464891',
					mainIllustrationCredit: 'Illustration by Norman Arlott.',
				},
				{
					commonName: '白領翡翠',
					scientificName: 'Todiramphus chloris',
					mainIllustrationAssetId: '151466371',
					mainIllustrationCredit: 'Illustration by Norman Arlott.',
				},
				{
					commonName: 'นกกระเต็นสร้อยคอสีน้ำตาล',
					scientificName: 'Actenoides concretus',
					mainIllustrationAssetId: '151465851',
					mainIllustrationCredit: 'Illustration by Tim Worfolk.',
				},
				{
					commonName: 'صياد السمك أبقع',
					scientificName: 'Ceryle rudis',
					mainIllustrationAssetId: '151464491',
					mainIllustrationCredit: 'Illustration by Tim Worfolk.',
				},
			],
		};
	},
	mounted() {},
	methods: {
		animate(index) {
			this.$set(this.animateSection, index, true);
		},
		formatPrice(price, decimals) {
			return (price / 100).toFixed(decimals);
		},
		openChargeBeePortal(e) {
			const event = new CustomEvent('openChargeBeePortal', { detail: e.target.id });
			document.dispatchEvent(event);
		},
	},
};
</script>

<style lang="scss" scoped>
body {
	scroll-behavior: smooth;
}
.anchor {
	position: relative;
	a {
		position: absolute;
		top: -6rem;
	}
}
.illustrationCards {
	display: grid;
	width: 100%;
	max-width: 100%;
	grid-auto-columns: repeat(3, minmax(0, 1fr));
	grid-auto-rows: repeat(7, 6rem);
	gap: $space-md;
	@include breakpoint(medium) {
		gap: $space-lg;
	}
}
.illustrationCard {
	max-width: 14rem;
	border: 4px solid $color-neutral-1;
	&:nth-child(1) {
		grid-column: 1 / 2;
		grid-row: 1 / 3;
	}
	&:nth-child(2) {
		grid-column: 2 / 3;
		grid-row: 2 / 4;
	}
	&:nth-child(3) {
		grid-column: 3 / 4;
		grid-row: 3 / 5;
		display: none;
		@include breakpoint(medium) {
			display: initial;
		}
	}
	&:nth-child(4) {
		grid-column: 2 / 3;
		grid-row: 4 / 6;
	}
	&:nth-child(5) {
		grid-column: 1 / 2;
		grid-row: 5 / 7;
	}
}
.Button--link {
	text-underline-offset: $space-rel-xs;
	text-decoration-line: underline;
	text-decoration-style: solid;
	text-decoration-thickness: 0.0625rem;
	text-decoration-color: rgba(44, 117, 201, 0.25);
}
.topBar {
	background-color: $brand-color-warm-black;
	@include inset-md;
	border-block-end: $color-neutral-4;
	position: sticky;
	top: 0;
	z-index: 10;
	display: grid;
	grid-auto-columns: 1fr auto 1fr;
	grid-auto-rows: 1fr;
	gap: $space-lg;
	align-items: center;
	.Heading {
		grid-column: 1 / 3;
		@include breakpoint(medium) {
			grid-column: 2 / 3;
		}
		grid-row: 1 / 2;
	}
	.topBar-button {
		text-align: right;
		grid-column: 3 / 4;
		grid-row: 1 / 2;
	}
}
.midBar {
	background-color: lighten($brand-color-warm-black, 10%);
	@include colorReverse;
	@include inset-md;
	display: flex;
	justify-content: center;
	gap: $space-lg;
	a {
		text-decoration: underline;
	}
}
ul.styled {
	list-style-position: inside;
	margin-left: 0;
	padding-left: 1.5rem;
	text-indent: -1.5rem;
	@include text(3, loose);
	@include breakpoint(large) {
		@include text(4, loose);
	}
}
.largeText {
	@include text(4);
	@include breakpoint(large) {
		@include text(5);
	}
}
.mediumText {
	@include text(3);
	@include breakpoint(large) {
		@include text(4);
	}
}
.funnel {
	overflow: hidden;
	position: relative;
	@include breakpoint(large) {
		display: grid;
		grid-auto-columns: 1fr 1fr;
		grid-auto-rows: 1fr 1fr;
		align-items: center;
	}
	.text {
		padding: $space-md;
		margin-bottom: $space-lg;
		@include breakpoint(large) {
			grid-column: 1 / 2;
			margin-bottom: 0;
			margin-left: $space-xl;
			transform: translateX(-50%);
			&.right {
				grid-column: 2 / 3;
				margin-left: 0;
				margin-right: $space-xl;
				transform: translateX(50%);
			}
			grid-row: 1 / 3;
			padding: $space-xl;
			transition: all 0.6s ease;
			opacity: 0;
			&.animate {
				transform: translateX(0);
				opacity: 1;
			}
		}
		&.overlay {
			margin: $space-md;
			@include breakpoint(large) {
				margin: $space-xl;
			}
			background-color: $color-overlay-black;
			border-radius: $space-md;
			&.white {
				background-color: $color-overlay-white;
				@include dropShadow(3);
			}
		}
	}
	.images {
		display: flex;
		gap: $space-lg;
		align-items: center;
		@include inset-responsive;
		@include breakpoint(large) {
			grid-column: 2 / 3;
			grid-row: 1 / 3;
			transform: translateX(50%);
			&.left {
				transform: translateX(-50%);
				grid-column: 1 / 2;
			}

			transition: all 0.6s ease;
			opacity: 0;
			&.animate {
				transform: translateX(0);
				opacity: 1;
			}
		}
		img {
			max-height: 250px;
			@include breakpoint(medium) {
				max-height: 500px;
			}
		}
	}
	.credit {
		@include text(1);
		@include inset-sm;
		position: absolute;
		right: 0;
		bottom: 0;
		&.reverse {
			color: $color-text-muted-reverse;
		}
	}
	.ebirdCredit {
		@include text(2);
		font-weight: bold;
		@include inset-md;
		position: absolute;
		left: 0;
		bottom: 0;
		background-color: $color-white;
		display: flex;
		align-items: center;
		gap: $space-sm;
		img {
			height: 1.5rem;
		}
	}
	.mlCredit {
		@include text(2);
		font-weight: bold;
		@include inset-md;
		position: absolute;
		left: 0;
		bottom: $space-md;
		@include breakpoint(medium) {
			bottom: 0;
		}
		display: flex;
		flex-direction: column;
		gap: $space-sm;
		background-color: $color-white;
		img {
			height: 1.5rem;
		}
	}
	&.yellowlegs {
		padding-top: 0;
		background-color: #ffd147;
		@include breakpoint(large) {
			background-image: url('https://is-headless-wordpress-prod-s3.s3.amazonaws.com/wp-content/uploads/sites/4/2022/02/yellowlegs_4.jpg');
			background-position: center;
			background-repeat: no-repeat;
			background-size: 100%;
		}
		@include breakpoint(large) {
			.text {
				width: 45%;
				margin: $space-xl;
				margin-right: 0;
			}
		}
		a {
			color: $color-black;
			text-decoration: underline;
		}

		@media screen and (min-width: 2000px) {
			// crazy wide
			min-height: 60vh;
		}
	}
	&.articles {
		padding-top: 0;
		background-color: #4c5b76;
		grid-auto-columns: 1fr 1.6fr;
		@include breakpoint(large) {
			margin-left: 0;
			.text.right {
				padding-right: 0;
			}
		}
		@include breakpoint(large) {
			background-image: url('https://is-headless-wordpress-prod-s3.s3.amazonaws.com/wp-content/uploads/sites/4/2022/02/southernfulmar_2.jpg');
			background-position: center;
			background-repeat: no-repeat;
			background-size: 100%;
		}
	}
	&.maps {
		background-image: url('https://is-headless-wordpress-prod-s3.s3.amazonaws.com/wp-content/uploads/sites/4/2022/01/blue_rock_thrush_map.jpg');
		background-position: center;
		background-attachment: fixed;
		background-repeat: no-repeat;
		background-size: 100%;
		padding: 8rem 0;
		@include breakpoint(large) {
			padding: 0;
			min-height: 80vh;
			.overlay {
				width: 40%;
			}
		}
	}
	&.multimedia {
		background-color: $color-secondary-6;
		.text {
			margin-bottom: 7rem;
		}
		.spect {
			max-width: 300px;
		}
		@include breakpoint(large) {
			.text {
				margin-bottom: 0;
			}
			.spect {
				max-width: none;
			}
			background-image: url('https://is-headless-wordpress-prod-s3.s3.amazonaws.com/wp-content/uploads/sites/4/2022/01/sunbird.jpg');
			background-position: center;
			background-repeat: no-repeat;
			background-size: 100%;
		}
	}
	&.taxonomy {
		background-color: $color-black;
		background-image: url('https://is-headless-wordpress-prod-s3.s3.amazonaws.com/wp-content/uploads/sites/4/2022/02/taxonomy_bg_2.jpg');
		background-position: center;
		background-attachment: fixed;
		background-repeat: no-repeat;
		min-height: 80vh;
		background-size: 200%;
		@include breakpoint(medium) {
			background-size: 100%;
		}
		.text {
			margin-bottom: 0;
		}
		.wagtailSubspecies {
			max-height: 360px;
		}
	}
	&.global {
		background-color: #000;
		background-image: url('https://is-headless-wordpress-prod-s3.s3.amazonaws.com/wp-content/uploads/sites/4/2022/02/earth_2.jpg');
		background-position: center;
		background-repeat: no-repeat;
		background-size: 100%;
	}
}

.subscribe {
	@include inset-lg;
	@include breakpoint(medium) {
		@include inset-xl;
	}
	display: flex;
	flex-direction: column;
	align-items: center;
	background-color: $color-primary-1;
	.cards {
		display: flex;
		gap: $space-lg;
		flex-direction: column;
		@include stack-lg;
		@include breakpoint(medium) {
			flex-direction: row;
		}
		.card {
			@include inset-squish-xl;
			@include dropShadow(2);
			background-color: $color-white;
			display: flex;
			flex-direction: column;
			align-items: center;
			gap: $space-md;
			text-align: center;
			.card-header {
				text-transform: uppercase;
				font-weight: bold;
				color: $color-text-muted;
			}
			.card-price {
				@include text(6);
				font-weight: bold;
			}
		}
	}
}

.howitworks {
	@include inset-responsive;
	@include colorReverse;
	background-color: $brand-color-forest-green-shade;
	display: flex;
	flex-direction: column;
	align-items: center;
	/*a {
		color: $brand-color-chartreuse-tint-2 !important;
		text-decoration-color: $brand-color-chartreuse-tint-2 !important;
		text-decoration-thickness: 0.5px;
		text-decoration: underline;
	}*/
	p {
		@include breakpoint(medium) {
			max-width: 40rem;
		}
	}
}

.previews {
	@include inset-responsive;
	background-color: $color-neutral-1;
}

$block: '.hex-grid';

@mixin grid-item($amount) {
	@for $i from 1 through $amount {
		&:nth-of-type(#{$amount}n + #{$i}) {
			grid-column: #{$i + $i - 1} / span 3;
			@if $i % 2 == 0 {
				grid-row: calc(var(--counter) + var(--counter) - 1) / span 2;
			}
		}
	}

	@for $i from 1 through 20 {
		&:nth-of-type(n + #{$i * $amount + 1}) {
			--counter: #{$i + 1};
		}
	}
}

#{$block} {
	display: flex;
	justify-content: center;

	&__list {
		--amount: 5;
		position: relative;
		padding: 0;
		margin: 0;
		list-style-type: none;
		display: grid;
		grid-template-columns: repeat(var(--amount), 1fr 2fr) 1fr;
		grid-gap: $space-sm $space-md;
	}

	&__item {
		position: relative;
		grid-column: 1 / span 3;
		grid-row: calc(var(--counter) + var(--counter)) / span 2;
		height: 0;
		padding-bottom: 90%;
		@include text(3);
	}

	&__content {
		position: absolute;
		height: 100%;
		width: 100%;
		color: $color-white;
		font-weight: bold;
		background-color: rgba(223, 223, 223, 0.2);
		clip-path: polygon(75% 0, 100% 50%, 75% 100%, 25% 100%, 0 50%, 25% 0);
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		padding: 2rem 25%;
		text-decoration: none;
		text-align: center;
	}
}

@media screen and (min-width: 1440px) {
	#{$block} {
		&__list {
			--amount: 5;
			--counter: 1;
		}

		&__item {
			@include grid-item(5);
			@include text(4);
		}
	}
}

@media screen and (min-width: 1200px) and (max-width: 1439px) {
	#{$block} {
		&__list {
			--amount: 5;
			--counter: 1;
		}

		&__item {
			@include grid-item(5);
		}
	}
}

@media screen and (min-width: 1024px) and (max-width: 1199px) {
	#{$block} {
		&__list {
			--amount: 5;
			--counter: 1;
		}

		&__item {
			@include grid-item(5);
		}
	}
}

@media screen and (min-width: 400px) and (max-width: 1023px) {
	#{$block} {
		&__list {
			--amount: 4;
			--counter: 1;
		}
		&__item {
			@include grid-item(4);
		}
	}
}

@media screen and (max-width: 399px) {
	#{$block} {
		&__list {
			--amount: 3;
			--counter: 1;
		}
		&__item {
			@include grid-item(3);
		}
	}
}
</style>
